:root {--animate-duration:1s;--animate-delay:1s;--animate-repeat:1}

.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

@-webkit-keyframes lightSpeedOutLeft {
    0% {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%,0,0) skewX(-30deg);
        transform: translate3d(-100%,0,0) skewX(-30deg)
    }
}

@keyframes lightSpeedOutLeft {
    0% {
        opacity: 1
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(-100%,0,0) skewX(-30deg);
        transform: translate3d(-100%,0,0) skewX(-30deg)
    }
}

.animate__lightSpeedOutLeft {
    -webkit-animation-name: lightSpeedOutLeft;
    animation-name: lightSpeedOutLeft;
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in
}

@-webkit-keyframes lightSpeedInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%,0,0) skewX(-30deg);
        transform: translate3d(100%,0,0) skewX(-30deg)
    }

    60% {
        opacity: 1;
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg)
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

@keyframes lightSpeedInRight {
    0% {
        opacity: 0;
        -webkit-transform: translate3d(100%,0,0) skewX(-30deg);
        transform: translate3d(100%,0,0) skewX(-30deg)
    }

    60% {
        opacity: 1;
        -webkit-transform: skewX(20deg);
        transform: skewX(20deg)
    }

    80% {
        -webkit-transform: skewX(-5deg);
        transform: skewX(-5deg)
    }

    to {
        -webkit-transform: translateZ(0);
        transform: translateZ(0)
    }
}

.animate__lightSpeedInRight {
    -webkit-animation-name: lightSpeedInRight;
    animation-name: lightSpeedInRight;
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out
}