.btn{
    box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.22);
    border-radius: 30px;
    display: inline-block;
    font-size: 15px;
    outline: none;
    padding: 12px 30px;
    transition: all 0.3s ease-in-out;
    text-decoration: none;
    min-width: 160px;
    text-align: center;
    
    &.btn-primary{
        color: #fff;
        border: 2px solid #fff;
        &:hover{
            background: #fff;
            color: #09aeea;
        }
    }

}