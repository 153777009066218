.about-me-box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    .about-me-left, .about-me-right{
        width: 45%;
    }
    .about-info{
        font-size: 15px;
        margin-bottom: 10px;
        span{
            color: #09aeea;
            font-weight: 600;
            margin-right: 5px;
        }
        a{
            color: #09aeea;
        }
    }
}
.about-me-text{
    color: #555;
    font-size: 15px;
    line-height: 25px;
    padding-bottom: 10px;;
}
.testimonial-wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .testimonial{
        box-shadow: 0 7px 12px rgba(0,0,0,.12);
        border: 2px solid #09aeea;
        border-radius: 20px;
        box-sizing: border-box;
        margin: 45px 0 25px;
        text-align: left;
        padding: 0 25px 20px 25px;
        position: relative;
        width: 48%;
        &::before{
            background: url(#{$image-folder-path}ashish.jpg) center center no-repeat;
            border: 4px solid #09aeea;
            background-size: cover;
            box-shadow: 0px 10px 10px -8px rgba(0,0,0,.22);
            border-radius: 50%;
            content: "";
            display: block;
            height: 90px;
            width: 90px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, -50%);
        }
        &.alok::before{background-image: url(#{$image-folder-path}alok.jpg);}
        &.qadir::before{background-image: url(#{$image-folder-path}qadir.jpg);}
        &.nasim::before{background-image: url(#{$image-folder-path}nasim.jpg);}
        .quote{
            fill: #09aeea;
            width:30px;
            position: absolute;
            right: 20px;
            bottom: 20px;
            opacity: 0.5;
        }
    }
    .testimonial-bottom{
        position: absolute;
        bottom: 20px;
    }
    .testimonial-text{
        color: #555;
        font-size: 15px;
        font-style: italic;
        line-height: 25px;
        padding: 60px 0 80px;
        
    }
    .testimonial-author{
        font-size: 15px;
        font-weight: 600;
        span{
            color: #a5a6a7;
            font-size: 12px;
            font-weight: normal;
        }
    }
    .testimonial-company{
        color: #a5a6a7;
        margin-top: 5px;
        svg{
            fill: #09aeea;
            height: 14px;
            margin-right: 5px;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .testimonial-wrapper{
        display: block;
        .testimonial{
            margin-bottom: 80px;
            width: 100%;
        }
        .testimonial-text{
            padding-bottom: 40px;
        }
        .testimonial-bottom{
            position: static;
        }
    }
}

@media only screen and (max-width: 767px) {
    .about-me-box{
        display: block;
        .about-me-left, .about-me-right{
            width: 100%;
        }
        .about-me-left{
            margin-bottom: 20px;
        }
    }
}