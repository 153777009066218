.navigation{
    background: #fff;
    border-radius: 32px;
    margin-left: auto;
    width: 70px;
    -webkit-box-shadow: 0 0 30px -5px rgba(0,0,0,.15);
    -moz-box-shadow: 0 0 30px -5px rgba(0,0,0,.15);
    box-shadow: 0 0 30px -5px rgba(0,0,0,.15);
    padding: 20px 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
    ul li{
        cursor: pointer;
        list-style: none;
        position: relative;
        padding: 10px 0;
        text-align: center;
        svg{
            fill: #b5b6b7;
            height: 30px;
            width:30px;
        }
        &.nav-home svg{height: auto; width: 35px;}
        span{
            background: #09aeea;
            border-radius: 15px 0 0 15px;
            color: #fff;
            opacity: 0;
            padding: 4px 10px;
            position: absolute;
            right: 0;
            top: 10px;
            text-align: center;
            transition: .3s;
            visibility: hidden;
            width: 90px;
            background: linear-gradient(90deg, #21e1ae, #09aeea);
        }
        &:hover span{
            right: 100%;
            opacity: 1;
            visibility: visible;
        }
        &:hover svg, &.active svg{
            fill:#09aeea;
        }
    }
}

@media only screen and(max-width: 768px) {
    .navigation{
        box-shadow: none;
        background: transparent;
        position: static;
        width: 100%;
        ul li{
            box-shadow: 0px 10px 10px -8px rgba(0, 0, 0, 0.22);
            border-radius: 30px;
            margin-bottom: 10px;
            text-align: left;
            padding: 10px 30px;
            &:hover, &.active{
                svg{fill: #fff;}
            }
            &.nav-home svg{
                height: 22px;
                width: 22px;
            }
            svg{
                height: 20px;
                width: 20px;
                fill: #fff;
                position: relative;
                top: 3px;
            }
            span{
                background: none;
                font-size: 16px;
                font-weight: 500;
                position: static;
                visibility: visible;
                opacity: 1;
            }
        }
    }
}